import './feedback-pop-up.css'
import axios from 'axios';
import { useState } from 'react';

function FeedbackPopUp(props) {

    const [content, setContent] = useState('');
    const [errorresponse, setErrorresponse] = useState(null);


    const token = localStorage.getItem('token');

    const axiosInstance = axios.create({
        baseURL: 'https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/', // Update with your API URL
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

    const submitFeedback = async () => {
        try {
            const response = await axiosInstance.post('/add-feedback/', {
                content: content,
                dashboard_id: props.dashboard
            });
            if (response.status === 201) {
                console.log('Feedback submitted successfully');
                props.function()
                props.onSuccess()
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setErrorresponse(true)
        }
    };

    return (
        <div className="roadmap-pop-up-table">  
            <div className="Feedback_Pop_Up">
                <div className="Feedback_Pop_Up_Header">     
                    <h2>Give us feedback</h2>
                    <h2 class='exit' onClick={props.function}>X</h2>  
                </div>
                <div className="Feedback_Pop_Up_Text"><p>We'd love to hear from you! Let us know what you think about the initiatives on our roadmap and the general direction we're going towards. If there's anything you'd like to see, let us know below.</p></div>
                <form class='main-feedback-form'>
                    <label>Provide us details of what you'd like to see</label>
                    <input type='text' onChange={(e) => {setContent(e.target.value)}}></input>
                    <button type='button' onClick={()=> {submitFeedback()}}>Submit</button>
                    {errorresponse ? <p>Sorry, we were unable to submit your feedback, please try again</p> : null}
                </form>
            </div>
        </div>
    );
  }
  
  export default FeedbackPopUp;