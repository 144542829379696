import './SettingsProfileDelete.css'


function DeleteAccountCard({onToggleDeletePop, onDeleteUser}) {

    const toggleDeletePop = () => {
        onToggleDeletePop()
    }

    const deleteUser = () => {
        onDeleteUser()
    }
  
    return (
        <div class='DeleteOuter'>
            <div class='DeletePopUp'>
                <h4>Are you sure?</h4>
                <p>All of your account data and portals will be deleted.</p>
                <div>
                    <button onClick={() => {deleteUser()}}>Delete</button>
                    <button onClick={() => {toggleDeletePop()}}>Cancel</button>
                </div>
            </div>
        </div>
    );
  }
  
  export default DeleteAccountCard;

