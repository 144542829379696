import { API_URL } from '../Config/config'
import axios from 'axios'
import { useState } from 'react';
import { useEffect } from 'react';

import './SettingsBilling.css'


function Billing() {

  const [trial, setTrial] = useState({ within_trial: false });
  const [subscription, setSubscription] = useState({is_active: false});
  
    const token = localStorage.getItem('token');

useEffect(() => {
  // Create axiosInstance inside the effect to capture the latest token
  const axiosInstance = axios.create({
    baseURL: 'https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/',
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  });

  const fetchTrialStatus = async () => {
    try {
      const response = await axiosInstance.get('check-trial-status/');
      setTrial(response.data);
    } catch (error) {
      console.error('Trial API failed:', error);
    }
  };

  const fetchBillingStatus = async () => {
    try {
      const response = await axiosInstance.get('check_subscription/');
      setSubscription(response.data);
    } catch (error) {
      console.error('Billing API failed:', error);
    }
  };

  // Call the functions to fetch the trial and billing status
  fetchTrialStatus();
  fetchBillingStatus();
}, [token]);
    
    // sub active, sub expired, no sub (within trial), no sub (out of trial)
    return (
        <div className='Settings-Billing'>
            <div className='billing-top-messaging'>
            {subscription.is_active === false ? (
              trial.within_trial === false ? (
                <p>Your trial has expired. Please purchase a plan in Account Settings.</p>
                ) : (
                <p>Purchase a plan before the end of your trial to continue accessing the site.</p>
              )
              ) : null}            
            </div>
            <h2>Billing</h2>
            {subscription.is_active ? null :
            <form action={`${API_URL}/api/create-checkout-session/`} method="POST">
                <input type="hidden" name="product_name" value="Web Portal" />
                <input type="hidden" name="price" value="price_1Nzg85J87r8sN9aTzHW1t8RX" />
                <input type="hidden" name="token" value={token} />
                <button className="checkout-managebilling" type="submit" >Checkout</button>
            </form>
            }
            {subscription.is_active ? 
            <form action={`${API_URL}/api/customer-portal/`} method="GET">
                <button className="checkout-managebilling" type="submit">Manage Billing</button>
            </form>
            : null }
        </div>
    );
}
  
export default Billing;