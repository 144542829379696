// auth.js

// Check if the user is authenticated (e.g., token is valid)
export const isAuthenticated = async () => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
  
    if (!token) {
      // If there's no token in local storage, the user is not authenticated
      console.log('User is not authenticated');
      return false;
    }
  
    try {
      // Make an API request to verify the token
      const response = await fetch('https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/verify-token/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
      });
  
      if (response.ok) {
        // If the API responds with success (e.g., status 200), the token is valid
        return true;
      } else {
        // If the API responds with an error (e.g., status 401), the token is invalid
        return false;
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      return false; // An error occurred, so assume the user is not authenticated
    }
  };
  