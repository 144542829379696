import { useState, useEffect, useCallback } from "react";
import fetchWorkspaces from '../AsanaServices/asanaWorkspaceService'
import fetchTeamData from "../AsanaServices/asanaTeamsService";
import fetchProjectsfromTeam from "../AsanaServices/asanaProjectfromTeamService";
import FetchTasksfromProjects from "../AsanaServices/asanaTasksfromProjectService";

import { useNavigate } from 'react-router-dom';

import axios from "axios";

import './onboarding.css'

import logo from '../static/looby.png'

function Onboarding() {

    const navigate = useNavigate();

    const [title,setTitle] = useState(null)
    const [description,setDescription] = useState(null)
    const [authorizationtoken,setAuthorizationtoken] = useState(null)
    const [workspaces,setWorkspaces] = useState(false)
    const [loading1,setLoading1] = useState(false)
    const [workspace,setWorkspace] = useState(false)
    const [link,setLink] = useState(false)

    /* Get Workspaces of a user*/
    function getWorkspaces() {
        setLoading1(true);
        (async () => {
        try {
            const workspacesdata = await fetchWorkspaces(authorizationtoken);
            setWorkspaces(workspacesdata);
        } catch (error) {
            console.error('Error fetching workspaces:', error);
        }
        })();
    }

    const [teams, setTeams] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [team,setTeam] = useState(false)

    /* Get Teams from a Workspace */
    const getTeams = useCallback(async () => {
        if (workspaces) {
          try {
            const teamsdata = await fetchTeamData(authorizationtoken, workspace);
            setTeams(teamsdata);
          } catch (error) {
            console.error('Error fetching teams:', error);
          }
          await new Promise(resolve => setTimeout(resolve, 800));
          setLoading2(false);
        }
      }, [workspaces, workspace, authorizationtoken]);

    const [projects, setProjects] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [project,setProject] = useState(false)


     /* Get Projects from a Team */
     const getProjects = useCallback(async () => {
        if (teams) {
          try {
            const projectsdata = await fetchProjectsfromTeam(authorizationtoken, team);
            setProjects(projectsdata);
          } catch (error) {
            console.error('Error fetching projects:', error);
          }
          await new Promise(resolve => setTimeout(resolve, 800));
          setLoading3(false);
        }
      }, [teams, team, authorizationtoken]);

    const [tasks, setTasks] = useState(false)
    const [loading4, setLoading4] = useState(false)

     /* Get Tasks from a Project */
     const getTasks = useCallback(async () => {
        if (projects) {
          try {
            const tasksdata = await FetchTasksfromProjects(authorizationtoken, project);
            setTasks(tasksdata);
          } catch (error) {
            console.error('Error fetching teams:', error);
          }
          await new Promise(resolve => setTimeout(resolve, 800));
          setLoading4(false);
        }
      }, [projects, project, authorizationtoken]);

    useEffect(() => {
        getTeams();
      }, [getTeams]);

    useEffect(() => {
        getProjects();
      }, [getProjects]);

    useEffect(() => {
        getTasks();
      }, [getTasks]);

      const token = localStorage.getItem('token');

      const axiosInstance = axios.create({
        baseURL: 'https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/', // Update with your API URL
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const createDashboard = async () => {
        try {
          const response = await axiosInstance.post('create-dashboard/', {
            title,
            'authorization_token': authorizationtoken,
            workspace,
            description,
            image : 0,
            team,
            project,
            link
          });
    
          if (response.status === 201) {
            // Dashboard created successfully
            console.log('Dashboard created:', response.data);
            navigate('/app');
            // You can redirect or update your UI as needed here
          } else {
            console.error('Dashboard creation failed:', response.statusText);
          }
        } catch (error) {
          console.error('Dashboard creation error:', error);
        }
      };
    

    return ( 
        <div className="Onboarding">
      <div className="Onboarding_Card">
        <img src={logo} width='200' alt=''/>
        <h3>New Portal Creation</h3>
        <div class='form'>
        <div class='two-input'>
            <div class='one-input'>
                <label>Title</label>
                <input type='text' onChange={(e) => {setTitle(e.target.value)}}></input>
            </div>
            <div class='one-input'>
                <label>Shareable link</label>
                <div className='domain'>
                    <p>www.luuby.com/</p>
                    <input type='text' placeholder='your-link' onChange={(e) => {setLink(e.target.value)}}></input>
                </div>
            </div>
        </div>
        <div class='two-input'>
            <div class='one-input'>
                <label>Description</label>
                <input type='text' onChange={(e) => {setDescription(e.target.value)}}></input>
            </div>
            <div class='one-input'>
                <label>Asana Authentication Token</label>
                <input type='text' onChange={(e) => {setAuthorizationtoken(e.target.value)}}></input>
            </div>
        </div>
        <div class='GetWorkspaces'>
            {workspaces ?
            <div>
            <p>Please select which workspace you'd like to use..</p>
            <select onChange={(e) => {
                const selectedWorkspaceName = e.target.value;
                const selectedWorkspace = workspaces.find(workspace => workspace.name === selectedWorkspaceName);
                if (selectedWorkspace) {
                    setWorkspace(selectedWorkspace.gid);
                }
            }}>
                <option disabled selected value> -- select an option -- </option>
                {workspaces.map(workspace => (
                <option key={workspace.id}>{workspace.name}</option>
                ))}
            </select>
            </div>
            :
            <button
                type="button"
                onClick={getWorkspaces}
                disabled={loading1}
            >
                {loading1 ? 'Loading...' : 'Connect to Asana'}
            </button>

            }
        </div>
        <div className="GetTeams">
            {workspace && !teams ? (
                loading2 ? <p>Loading...</p> : null
            ) : workspace && teams ? (
                <div>
                <p>Please select which team you'd like to use..</p>
                <select onChange={(e) => {
                    const selectedTeamName = e.target.value;
                    const selectedTeam = teams.find(team => team.name === selectedTeamName);
                    if (selectedTeam) {
                        setTeam(selectedTeam.gid);
                    }
                }}>
                    <option disabled selected value> -- select an option -- </option>
                    {teams.map((team) => (
                    <option key={team.id}>{team.name}</option>
                    ))}
                </select>
                </div>
            ) : null}
        </div>
        <div className="GetProjects">
        {team && !projects ? (
            loading3 ? <p>Loading...</p> : null
            ) : team && projects ? (
            <div>
                <p>Please select which project you'd like to use..</p>
                <select onChange={(e) => {
                    const selectedProjectName = e.target.value;
                    const selectedProject = projects.find(project => project.name === selectedProjectName);
                    if (selectedProject) {
                        setProject(selectedProject.gid);
                    }
                }}>
                    <option disabled selected value> -- select an option -- </option>
                    {projects.map(project => (
                        <option key={project.id}>{project.name}</option>
                    ))}
                </select>
            </div>
            ) : null}
        </div>
        <div class='GetTasks'>
        {project && !tasks ? (       
                loading4 ? <p>Loading...</p>: null
        ) : project && tasks ? (
            <div>
                <p>These are the tasks that will be included in your roadmap:</p>
                <ul>
                    {tasks.map(task => (
                    <li key={task.id}>{task.name}</li>
                    ))}
                </ul>
            </div>
        )
            : null }
        </div>
        {tasks && project ?
        <button onClick={createDashboard}>Create roadmap</button> :
        null}
        </div>
      </div>
      </div>
    );
  }
  
  export default Onboarding;