import './AccountNav.css';
import { useState, useRef, useEffect } from 'react';
import DropDown from './components/Dropdown';


import { MdSettings } from 'react-icons/md';


function AccountNavigation() {
    const [menuopen, setMenuopen] = useState(false)
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setMenuopen((prevState) => !prevState);
    };

    const handleClosemenu = () => {
        setMenuopen(false);
    }

    useEffect(() => {
        function handleClickOutside(event) {
                if (menuRef.current && menuopen && !menuRef.current.contains(event.target)) {
                    // Click occurred outside the dropdown, so close it
                    handleClosemenu();
            }
        }
        // Add the event listener when the dropdown is open
        if (menuopen && menuRef.current) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
    
        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [menuopen]);

    return (
        <div className="AccountNavigation" ref={menuRef} >
            <MdSettings onClick={toggleMenu} style={{ cursor: 'pointer'}} />
            { menuopen === true ?
            <DropDown />
            : null
            }
        </div>
    );
}

export default AccountNavigation;
