import { Link } from 'react-router-dom';
import './DropDown.css'

function DropDown() {
    const handleLogout = () => {
        // Clear the token from storage (localStorage or sessionStorage)
        localStorage.removeItem('token');
    
        // Redirect the user to the login page (you can change the path as needed)
        window.location.reload()
    };

    return (
        <div class='DropDown'>
            <Link to='settings' >
                <p class='Account-link'>Account Settings</p>
            </Link>
            <p onClick={handleLogout}>Logout</p>
        </div>
    )
}

export default DropDown