import { Link } from "react-router-dom"

const Cancel = () => {
    return (
        <section>
            <div class="product Box-root">
                <div class="description Box-root">
                    <h3>Checkout successful!</h3>
                    <Link to='/app'><button>Back to Account</button></Link>
                </div>
            </div>        
      </section>
    )
}

export default Cancel