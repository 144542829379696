import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import AccountNavigation from './AccountNav';

import { MdContentCopy, MdOpenInNew, MdCheck, MdMoreVert, MdHourglassEmpty, MdDone, MdClear } from 'react-icons/md';

import DashboardDropDown from './components/DashboardDropDown';

import axios from 'axios';

import './Director.css'
import PasswordChange from './components/PasswordChange';
import DeleteDashboard from './components/DeleteDashboard';
import PasswordSet from './components/PasswordSet';

function Director() {
  const [isDashboardVerified, setIsDashboardVerified] = useState(null);
  const [dashboardData, setDashboardData] = useState(null); // Initialize as null

  const [isEditing, setIsEditing] = useState(false);
  const [editingDashboard, setEditingDashboard] = useState(null);
  const [editedDashboard, setEditedDashboard] = useState(null)
  const [editingDashboardName, setEditingDashboardName] = useState(null)
  const [editingDashboardLink, setEditingDashboardLink] = useState(null)
  const [editingDashboardPassword, setEditingDashboardPassword] = useState(null)
  const [deleteDashboard, setDeleteDashboard] = useState(null)
  const [setDashboardPassword,setSetDashboardPassword] = useState(null)

  const token = localStorage.getItem('token'); // Retrieve the token from localStorage

  const [isLinkAvailable, setIsLinkAvailable] = useState(true); // Initially assume link is available
  const [isCheckingLink, setIsCheckingLink] = useState(false);

  const dropDownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        // Check if the click target is the burger icon
        if (!event.target.closest('.burger-icon')) {
          // Clicked outside of the dropdown (excluding the burger icon), close it
          setIsEditing(false);
          setEditingDashboard(null);
        }
      }
    };

    // Add event listener to handle clicks outside the dropdown
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropDownRef]);

  useEffect(() => {
    const checkLinkAvailability = async () => {
      if (editingDashboardLink !== null && editedDashboard !== null) {
        setIsCheckingLink(true);
        try {
          const linkToCheck = editedDashboard.link; // Create a variable to hold the link value
          // Make an API call to check link availability
          const response = await fetch(`https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/check_link_availability/${linkToCheck}/`);
          const data = await response.json();
          setIsLinkAvailable(data.isAvailable);
        } catch (error) {
          console.error('Error checking link availability:', error);
        } finally {
          await new Promise(resolve => setTimeout(resolve, 800));
          setIsCheckingLink(false);
        }
      }
    };
  
    checkLinkAvailability();
  }, [editingDashboardLink, editedDashboard]);


  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/user_dashboards/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setDashboardData(data);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    }

    // Call fetchData only when the component mounts
    fetchData();
  }, [token]);

  useEffect(() => {
    // Set isDashboardVerified based on dashboardData
    if (dashboardData !== null) {
      setIsDashboardVerified(dashboardData.length !== 0);
    }
  }, [dashboardData]);

  const [copiedDashboard, setCopiedDashboard] = useState(null);

  const copyToClipboard = (text, dashboard) => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    setCopiedDashboard(dashboard);
    console.log(copiedDashboard)

    // Reset the copiedDashboard state after 10 seconds
    setTimeout(() => {
      setCopiedDashboard(null);
    }, 5000);
  };

  const handleEditDashboard = (dashboard) => {
    setIsEditing(!isEditing);
    setEditingDashboard(dashboard);
    setEditedDashboard(dashboard)
  };

  const handleCloseDashboard = () => {
    setIsEditing(false);
    setEditingDashboard(null);
  };

  const handleDataChange = (editedDashboard) => {
    setDashboardData((prevData) =>
        prevData.map((dashboard) =>
          dashboard.id === editedDashboard.id ? editedDashboard : dashboard
        )
      );
  }

  const handleEditDashboardName = (dashboard) => {
    if (editingDashboardName === dashboard) {
      setEditingDashboardName(null)
    }
    else {
      setEditingDashboardName(dashboard)
    }
  }

  const handleEditDashboardLink = (dashboard) => {
    if (editingDashboardLink === dashboard) {
      setEditingDashboardLink(null)
    }
    else {
      setEditingDashboardLink(dashboard)
    }
  }

  const handleEditDashboardPassword = (dashboard) => {
    if (editingDashboardPassword === dashboard) {
      setEditingDashboardPassword(null)
    }
    
    else {
      setEditingDashboardPassword(dashboard)
      handleCloseDashboard()
    }
  }

  const handleSetDashboardPassword = (dashboard) => {
    if (setDashboardPassword === dashboard) {
      setSetDashboardPassword(null)
    }
    
    else {
      setSetDashboardPassword(dashboard)
    }
  }

  const handleDeleteDashboard = (dashboard) => {
    if (deleteDashboard === dashboard) {
      setDeleteDashboard(null)
    }
    
    else {
      setDeleteDashboard(dashboard)
      handleCloseDashboard()
    }
  }

  const handlePasswordClose = () => {
    setEditingDashboardPassword(null)
    setSetDashboardPassword(null)

  }

  const handleTitleChange = (e) => {
    setEditedDashboard({ ...editedDashboard, title: e.target.value });
  };

  const handleLinkChange = (e) => {
    setEditedDashboard({ ...editedDashboard, link: e.target.value });
  };

  const handleSave = async (dashboard) => {
    try {
        const response = await fetch(`https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/update_dashboard_name/${editedDashboard.id}/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
            body: JSON.stringify({ title: editedDashboard.title, link: editedDashboard.link, password: editedDashboard.password }),
        });
        if (response.ok) {
          console.log(response)
          handleCloseDashboard(dashboard)
          handleDataChange(editedDashboard)
        } else {
            console.error('No data to change', response.status);
        }
    } catch (error) {
        console.error('Error fetching dashboard data:', error);
    }
};

const handleDashboardDeleteClose = () => {
  setDeleteDashboard(null)
}

const [trial, setTrial] = useState({ within_trial: false });
const [subscription, setSubscription] = useState({is_active: false});

useEffect(() => {
  // Create axiosInstance inside the effect to capture the latest token
  const axiosInstance = axios.create({
    baseURL: 'https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/',
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  });

  const fetchTrialStatus = async () => {
    try {
      const response = await axiosInstance.get('check-trial-status/');
      setTrial(response.data);
    } catch (error) {
      console.error('Trial API failed:', error);
    }
  };

  const fetchBillingStatus = async () => {
    try {
      const response = await axiosInstance.get('check_subscription/');
      setSubscription(response.data);
    } catch (error) {
      console.error('Billing API failed:', error);
    }
  };

  // Call the functions to fetch the trial and billing status
  fetchTrialStatus();
  fetchBillingStatus();
}, [token]);

  return (
    <div className="Director">
      <AccountNavigation/>
      {isDashboardVerified !== null ? (
        isDashboardVerified ? (
          <div class='Dashboards'>
            <div className='billing-top-messaging'>
              {subscription.is_active === false ? (
              trial.within_trial === false ? (
                <p>Your trial has expired. Please purchase a plan in Account Settings.</p>
                ) : (
                <p>Purchase a plan in Account Settings before the end of your trial to continue accessing the site.</p>
              )
              ) : null}
            </div>
              <h2>Your Portals</h2>
              <div class='Dashboard-container'>
                <ul class='headers'>
                  <li class='title-header'>Title</li>
                  <li class='live-header'>Live</li>
                  <li class='password-header'>Password</li>
                  <li class='link-header'>Link</li>
                  <li class='actions-header'>Actions</li>
                </ul>
              <ul>
                {dashboardData.map((dashboard, index) => (
                <div key={dashboard.id}>
                  <div class='row' >
                    {editingDashboardName === dashboard ? <div class='title-row-edit-container'><input class='title-row-edit' type='text' value={editedDashboard.title} onChange={handleTitleChange}/></div> : <p class='title-row' >{dashboard.title}</p>}
                    <div class='live-row'>
                      {(subscription != null && subscription.is_active) || trial.within_trial ? <button className='live'>Live</button> : <button className='not-live'>Not Live</button>}
                    </div>
                    <p class='password-row'>{dashboard.password_required ? 'Yes' : 'No'}</p>
                    <div class='link-row'>
                    {editingDashboardLink === dashboard ? (
                      <>
                        <input class='link-row-edit' type='text' value={editedDashboard.link} onChange={handleLinkChange} />
                        {editedDashboard.link === editingDashboardLink.link ? null :
                          isCheckingLink ? (
                            <MdHourglassEmpty style={{ color: 'orange' }} />
                          ) : (
                            <>
                              {editedDashboard && isLinkAvailable && editedDashboard.link !== '' ? (
                                <div class='av-unav-container'><MdDone style={{ color: 'green' }}/><p class='av-unav av'>Available</p></div>
                              ) : (
                                <div class='av-unav-container'><MdClear style={{ color: 'red' }}/><p class='av-unav unav'>Unavailable</p></div>
                              )}
                            </>
                          )
                        }
                      </>
                    ) : (
                      <>
                        {dashboard && (
                          <>
                            <p>{dashboard.link}</p>
                            <Link to={dashboard.link} target="_blank"><MdOpenInNew/></Link>
                            <MdContentCopy onClick={() => copyToClipboard(`www.luuby.co.uk/app/${dashboard.link}`, dashboard.link)}
                              style={{ cursor: 'pointer' }} />
                            {copiedDashboard === dashboard.link ? <MdCheck style={{ color: 'green' }} /> : null}
                          </>
                        )}
                      </>
                    )}
                    </div>
                    {editingDashboardName !== dashboard && editingDashboardLink !== dashboard ? (
                      <>
                        <MdMoreVert class='burger-icon' onClick={() => handleEditDashboard(dashboard)} style={{ cursor: 'pointer'}}/>
                        {editingDashboard === dashboard && isEditing ?
                        <div ref={dropDownRef}><DashboardDropDown onClose={()=> {handleCloseDashboard()}} index= {index} onDashboardEditName={() => handleEditDashboardName(dashboard)} onDashboardEditLink={() => handleEditDashboardLink(dashboard)} onDashboardEditPassword={() => handleEditDashboardPassword(dashboard)} onDashboardSetPassword={() => handleSetDashboardPassword(dashboard)} onDeleteDashboard={() => handleDeleteDashboard(dashboard)} dashboard={editingDashboard}/></div>: null }
                        {editingDashboardPassword === dashboard ? <PasswordChange onPasswordClose={() => handlePasswordClose()} dashboard={editedDashboard}/> : null}
                        {setDashboardPassword === dashboard ? <PasswordSet onPasswordClose={() => handlePasswordClose()} dashboard={editedDashboard}/> : null}
                        {deleteDashboard === dashboard ? <DeleteDashboard onClose={() => handleDashboardDeleteClose()} dashboard={editedDashboard}/> : null}
                      </>
                    ) : (
                      <div class='table-buttons'>
                        {isLinkAvailable && editedDashboard.link !== '' ? <button class='table-submit'onClick={() => handleSave(dashboard)}>Submit</button> : <button class='table-submit-disabled'>Submit</button>}
                        <button class='table-cancel' onClick={() => {setEditingDashboardName(null); setEditingDashboardLink(null); setIsLinkAvailable(true);}}>Cancel</button>
                      </div>
                      )}
                  </div>
                </div>
                ))}
              </ul>
            </div>
            <div class='button_to_onboarding_container'>
              {subscription.is_active === false && trial.within_trial === false ? <button class='disabled-portal-button'>Create New Portal</button> : <Link to='/onboarding'><button class='enabled-portal-button'>Create New Portal</button></Link> }
            </div>
          </div>
        ) : (
          <div class='button_to_onboarding_container'>
            <Link to='/onboarding'><button>Create Portal</button></Link>
          </div>
        )
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Director;






