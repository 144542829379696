import React from 'react';
import {Navigate} from 'react-router-dom';

const PublicRoute = ({ element, isAuthenticated }) => {

  // Check if the user is authenticated
  if (!isAuthenticated) {
    return element;
  } else {
    // Redirect to the login page with a return URL
    return <Navigate to={`/app`} />;
  }
};

export default PublicRoute;