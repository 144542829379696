import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Error from './error';

function Feedback() {
  const { dashboard_id } = useParams();
  const [feedback, setFeedback] = useState([]);

  const token = localStorage.getItem('token');

  const axiosInstance = axios.create({
    baseURL: 'https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/', // Update with your API URL
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  });

  useEffect(() => {
    // Fetch feedback data for the specific dashboard
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`feedback/${dashboard_id}/`);
        setFeedback(response.data);
      } catch (error) {
        console.error('Error fetching feedback:', error);
        setFeedback(false)
      }
    };

    fetchData();
  }, [dashboard_id, axiosInstance]);

  return (
    <div>
      {feedback === false ? <Error/> : (
        <>
      <h1>Feedback for Dashboard ID {dashboard_id}</h1>
      <ul>
        {feedback.map((item) => (
          <li key={item.id}>{item.content}</li>
        ))}
      </ul>
      </>
      )}
    </div>
  );
}

export default Feedback;