import React, { useState } from 'react';
import './PasswordChange.css'

import axios from 'axios';

function PasswordSet({onPasswordClose, dashboard}) {
  const [newpassword, setNewPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');

  const handleClose = () => {
    // Call the onPasswordSubmit callback with the entered password
    onPasswordClose();
  };

  const token = localStorage.getItem('token');  // Retrieve the token from local storage


  async function changeDashboardPassword(dashboardId,  newPassword, confirmPassword) {
    try {
      const axiosInstance = axios.create({
        baseURL: 'https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/',  // Update with your API URL
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      const response = await axiosInstance.put(`change_password/${dashboardId}/`, {
        new_password: newPassword,
        confirm_password: confirmPassword,
      });
  
      if (response.status === 200) {
        // Password changed successfully
        console.log('Password changed successfully:', response.data.detail);
        window.location.reload()
        return true;
      } else {
        // Handle error responses
        console.error('Password change failed:', response.data.detail);
        return false;
      }
    } catch (error) {
      console.error('Error changing password:', error);
      return false;
    }
  }

  return (
    <div class='Password_Background'>
    <div class='PasswordChange'>
        <button class='password_change_close_button' onClick={handleClose}>X</button>
        <div class='password_change_form'>
            <h3>Set Password</h3>
            <div class='password_change_inputs'>
                <input
                type="password"
                placeholder="New Password"
                value={newpassword}
                onChange={(e) => setNewPassword(e.target.value)}
                />
                <input
                type="password"
                placeholder="Confirm New Password"
                value={confirmpassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                />
            </div>
        <button class='password_change_button' onClick={() => {changeDashboardPassword(dashboard.id, newpassword, confirmpassword)}}>Submit</button>
      </div>
    </div>
    </div>
  );
}

export default PasswordSet;