import * as React from 'react'
import { useState, useEffect } from "react"
import { Link } from 'react-router-dom'

import "./LandingPageNavigation.css"

import logo from '../static/looby1.png'

const LandingPageNavigation = () => {
  //navbar scroll when active state
  const [navbar, setNavbar] = useState(false)

  //navbar scroll changeBackground function
  const changeBackground = () => {
    console.log(window.scrollY)
    if (window.scrollY >= 66) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })

  return (
    
    <nav className='nav'>
        <div className = {navbar ? 'navbar1' : 'navbar2'}>
            <Link className = 'nav_img' to="/"><img alt='logo' height='120px' src={logo}/></Link>
            <ul className = 'nav_list'>
                <li><Link to="/login"><button>Get started</button></Link></li>
            </ul>
        </div>
    </nav>
    

  )
}

export default LandingPageNavigation