import LoginDesktop from "../components/login-desktop";
import LoginMobile from "../components/login-mobile";


const Login = () => {

  
    return (
      <>
        <LoginDesktop/>
        <LoginMobile />
      </>
    );
  };

export default Login;

