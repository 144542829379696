// APP
import './App.css';
import Login from './pages/login';
import Roadmap from './pages/roadmap';
import Onboarding from './pages/onboarding';
import Director from './Director';
import Error from './pages/error';
import Settings from './pages/settings';

import Purchase from './pages/purchase';
import Success from './pages/success';
import Cancel from './pages/cancel';
import ForgotPassword from './pages/ForgotPassword';
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from "@stripe/stripe-js/pure"
import { REACT_APP_STRIPE_KEY } from './Config/config';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute'; // Import your PrivateRoute component
import PublicRoute from './PublicRoute';
import { isAuthenticated } from './auth';

import { useState, useEffect } from 'react';
import Feedback from './pages/feedback';
import ResetPassword from './pages/ResetPassword';

// LANDING PAGE
import LandingPage from './website/LandingPage';

const stripe_key = REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(stripe_key)


function App() {
  return (
    <div className="App">
      <Elements stripe={stripePromise} >
      <Router>
        <AppRoutes />
      </Router>
      </Elements>
    </div>
  );
}

function AppRoutes() {

  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(null);

  useEffect(() => {
    // Check if the user is authenticated when the component mounts
    isAuthenticated().then((result) => {
      setIsAuthenticatedUser(result);
    });
  }, []); // The empty dependency array ensures this effect runs once, like componentDidMount

  if (isAuthenticatedUser === null) {
    // While waiting for authentication check to complete, you can show a loading indicator
    return <div>Loading...</div>;
  }

    // Check if the user is authenticated
    /*if (!isAuthenticatedUser) {
      // Redirect to the login page with a return URL
      return <Navigate to={`/login`} />;
    }*/

  // Conditionally render Navigation based on the route path
  //const renderNavigation = location.pathname !== '/login' && location.pathname !== '/onboarding' && location.pathname !== '/onboarding/' && location.pathname !='/app/' && location.pathname !='/app' && location.pathname !='/billing/' && location.pathname !='/billing' && location.pathname !='/error/' && location.pathname !='/error';
  //{renderNavigation && <Navigation />}

  return (
    <>
    <Routes>
      <Route path="/app/*" element={<Roadmap />} />
      <Route path="/app" element = {<PrivateRoute element={<Director />} isAuthenticated={isAuthenticatedUser} />} />
      <Route path="/login" element = {<PublicRoute element={<Login />} isAuthenticated={isAuthenticatedUser} />} />
      <Route path="/onboarding" element = {<PrivateRoute element={<Onboarding />} isAuthenticated={isAuthenticatedUser} />} />
      <Route path="/error" element={<Error />} />
      <Route path="/app/settings" element = {<PrivateRoute element={<Settings/>} isAuthenticated={isAuthenticatedUser} />} />
      <Route path="/checkout" element = {<PrivateRoute element={<Purchase/>} isAuthenticated={isAuthenticatedUser} />} />
      <Route exact path='checkout/success/' element = {<PrivateRoute element={<Success/>} isAuthenticated={isAuthenticatedUser} />} />
      <Route exact path='checkout/failed/' element = {<PrivateRoute element={<Cancel/>} isAuthenticated={isAuthenticatedUser} />} />
      <Route path="/app/feedback/:dashboard_id" element = {<PrivateRoute element={<Feedback/>} isAuthenticated={isAuthenticatedUser} />} />
      <Route path="/forgot" element = {<PublicRoute element={<ForgotPassword/>} isAuthenticated={isAuthenticatedUser} />} />
      <Route path="/reset-password/*" element = {<PublicRoute element={<ResetPassword/>} isAuthenticated={isAuthenticatedUser} />} />
      <Route path="/" element = {<PublicRoute element={<LandingPage/>} isAuthenticated={isAuthenticatedUser} />} />
      <Route path="/*" element = {<PublicRoute element={<Error/>} isAuthenticated={isAuthenticatedUser} />} />
    </Routes>
  </>
  );
}

export default App;
