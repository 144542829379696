import './feedback-success.css'


function FeedbackSuccess({exitfunction}) {

    const onClose = () => {
        exitfunction()
    }


    return (
        <div className="FeedbackSuccess_Background">  
            <div className="Feedback_Success">
                <h3 class='exit' onClick={()=>{onClose()}}>X</h3>  
                <h4>Feedback successfully submitted!</h4>
            </div>
        </div>
    );
  }
  
  export default FeedbackSuccess;