import './DeleteDashboard.css'

function DeleteDashboard({onClose, dashboard}) {

    const token = localStorage.getItem('token'); // Retrieve the token from localStorage

    const handleClose = () => {
        // Call the onPasswordSubmit callback with the entered password
        onClose();
      };

    const handleDeleteDashboard = async (dashboard) => {
        try {
          const response = await fetch(`https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/delete_dashboard/${dashboard.id}/`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${token}`,
            },
          });
    
          if (response.ok) {
            // Dashboard deleted successfully, you can update your state or perform any necessary actions here.
            console.log('Dashboard deleted successfully.');
            onClose();
            window.location.reload()
          } else {
            // Handle errors or display error messages here.
            console.error('Error deleting dashboard:', response.statusText);
          }
        } catch (error) {
          console.error('Error deleting dashboard:', error);
        }
      };

    return (
        <div class='DeleteDashboardBackground'>
            <div class='DeleteDashboard'>
                <button class='delete_dashboard_close_button' onClick={handleClose}>X</button>
                <h2>Delete Dashboard</h2>
                <p>Are you sure?</p>
                <div class='delete_dashboard_buttons'>
                    <button class='delete_dashboard_cancel_button' onClick={handleClose}>Cancel</button>
                    <button class='delete_dashboard_delete_button' onClick={() => handleDeleteDashboard(dashboard)}>Delete</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteDashboard