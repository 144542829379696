// Step 1: Import React
import * as React from 'react'
import LandingPageNavigation from './LandingPageNavigation'
import { Link } from 'react-router-dom'      

import './LandingPage.css'
import Image from '../static/Roadmap.png'

import auth from '../static/insurance.png'
import build from '../static/team-building.png'
import share from '../static/send.png'
import improve from '../static/improve.png'

import Onboarding from '../static/Onboarding.png'
import Password from '../static/Password.png'
import Director from '../static/Director.png'

// Step 2: Define your component
const LandingPage = () => {
  return (

    <div>
        <title>Luuby</title>
    <LandingPageNavigation />
    
    <main>
      <div className='top_index'>
        <div className='top_index_left'>
          <h1>Build beautiful roadmaps <br></br> with ease</h1>
          <p>Connect your Asana account, and create a roadmap in minutes that you can share with anyone </p>
          <Link to="/login"><button className='get_started'>Get started for free</button></Link>
        </div>
        <div className='top_index_right'>
          <img alt='main_image' src={Image}></img>
        </div>
      </div>
      <div className='mid_index'>
        <div className='step'>
          <div className='step_top'>
            <img alt='icon_learn' width='30px' height='30px' src={auth}></img>
            <h3>Authenticate</h3>
          </div>
          <div className='step_bottom'>
            <p>We take your privacy seriously, so we have a robust authentication process to ensure the safety of your Asana data whilst transferring it to your new roadmaps.</p>
          </div>
        </div>
        <div className='step'>
          <div className='step_top'>
            <img alt='icon_fix' width='30px' height='30px' src={build}></img>
            <h3>Build</h3>
          </div>
          <div className='step_bottom'>
            <p>Our user-friendly interface empowers you to swiftly create a comprehensive and visually appealing roadmap that aligns with your strategic goals. </p>
          </div>
        </div>
        <div className='step'>
          <div className='step_top'>
            <img  alt='icon_diary' width='30px' height='30px' src={share}></img>
            <h3>Share</h3>
          </div>
          <div className='step_bottom'>
            <p>Foster transparency and alignment by distributing dynamic, interactive roadmaps, enhancing project communication and ensuring collective success.</p>
          </div>
        </div>
        <div className='step'>
          <div className='step_top'>
            <img  alt='icon_exam' width='30px' height='30px' src={improve}></img>
            <h3>Improve</h3>
          </div>
          <div className='step_bottom'>
            <p>Receive valuable feedback effortlessly through your shared roadmaps by allowing stakeholders to provide direct feedback.</p>
          </div>
        </div>
        
      </div>
      <div className='feature_index'>
        <div className='feature_diary'>
          <img  alt='onboarding' src={Onboarding} ></img>
          <div className='feature_desc'>
            <h3>Effortless Setup Process</h3>
            <p>Experience a seamless journey with our user-friendly platform that allows you to build a roadmap in 3 steps.</p>
            <Link to='/login'>Find out more</Link>
          </div>
        </div>
        <div className='feature_exam'>
          <div className='feature_desc'>
            <h3>Streamlined Roadmap Management</h3>
            <p>Efficiently manage your roadmaps with our intuitive features. You can edit titles, update links, and review feedback for each roadmap.</p>
            <Link to='/login'>Find out more</Link>
          </div>
          <img  alt='director' src={Director} ></img>
        </div>
        <div className='feature_learning'>
          <img alt='password' src={Password} ></img>
          <div className='feature_desc'>
            <h3>Securely Shareable Roadmaps</h3>
            <p>Take command of your shared roadmaps with robust security features. You can fully control access, including setting passwords for external stakeholders.</p>
            <Link to='/login'>Find out more</Link>
          </div>
        </div>
      </div>
      <div className='bottom_cta'>
        <h2>Create a free account to get started</h2>
        <Link to="/login" ><button className='bottom_cta_button'>Get started for free</button></Link>
        <p>Join 100s of other companies who are building their roadmaps with Luuby</p>
      </div>
    </main>
    </div>
  )
}

// Step 3: Export your component
export default LandingPage