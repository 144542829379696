import { useState, useEffect } from "react";

import { useNavigate } from 'react-router-dom';

import DeleteAccountCard from "./SettingsProfileDelete";

import './SettingsProfile.css'

function Profile() {
    const navigate = useNavigate(); // Get the navigate function from React Router

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [oldPassword, setOldPassword] = useState('')


    const [emailButton,setEmailButton] = useState('')
    const [emailMessaging,setEmailMessaging] = useState(null)

    const [passwordButton,setPasswordButton] = useState('')

    const [deletePop,setDeletePop] = useState(false)

    const toggleDeletePop = () => {
        setDeletePop(!deletePop)
    }

    const [success, setSuccess] = useState(null)

    
    useEffect(() => {
        if (email.length !== 0) {
          // Use a regular expression to check for a valid email format
          const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      
          // Set emailButton to true only if the email is valid
          setEmailButton(isValidEmail);
        } else {
          setEmailButton(false);
          setEmailMessaging("Please enter a valid email address")
        }
      }, [email]);

    useEffect(() => {
        if (password.length !== 0 && confirmPassword.length !== 0 && oldPassword.length !== 0 && password === confirmPassword) {
            setPasswordButton(true)
        }
        if (password.length === 0 || password === '' || password === null || confirmPassword.length === 0 || confirmPassword === '' || confirmPassword === null || oldPassword.length === 0 || oldPassword === '' || oldPassword === null) {
            setPasswordButton(false)
        }

        if (password.length !== 0 && confirmPassword.length !== 0 && password !== confirmPassword) {
            setPasswordButton('Not equal')
        }
    },
    [password,confirmPassword, oldPassword]);

    const token = localStorage.getItem('token'); // Retrieve the token from localStorage

    // Update Email function
    const updateEmail = async (newEmail) => {
        try {
            const response = await fetch(`https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/update_email/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
                body: JSON.stringify({ new_email: newEmail }), // Include 'new_email' in the JSON body
            });
            if (response.ok) {
              console.log(response)
              setSuccess('Email successfully changed')
              setTimeout(() => {
                setSuccess(null);
              }, 3000);
            } else {
                console.error('No email change', response.status);
                setSuccess('Email change failed')
                setTimeout(() => {
                    setSuccess(null);
                  }, 3000);
            }
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
            setSuccess('Email change failed')
            setTimeout(() => {
                setSuccess(null);
              }, 3000);
        }
    };

    // Update Password function
    const updatePassword = async (old_password, new_password, confirm_new_password) => {
        try {
            const response = await fetch(`https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/change_password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
                body: JSON.stringify({ old_password: old_password, new_password: new_password, confirm_new_password: confirm_new_password }), // Include 'new_email' in the JSON body
            });
            if (response.ok) {
                setSuccess('Password successfully changed')
                setTimeout(() => {
                    setSuccess(null);
                  }, 3000);
            } else {
                setSuccess('Password change failed')
                setTimeout(() => {
                    setSuccess(null);
                  }, 3000);
            }
        } catch (error) {
            setSuccess('Password change failed')
            setTimeout(() => {
                setSuccess(null);
              }, 3000);
        }
    };

    const deleteUser = async () => {
        try {
            const response = await fetch(`https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/delete_profile/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            });
            if (response.ok) {
              navigate('/app');
            } else {
                setSuccess("Couldn't delete user")
                setTimeout(() => {
                    setSuccess(null);
                  }, 3000);
            }
        } catch (error) {
            setSuccess("Couldn't delete user")
            setTimeout(() => {
                setSuccess(null);
              }, 3000);
        }
    };

    return (
        <div class='Settings-Profile'>
            {success ? <div class='success-container'><p>{success}</p></div> : null}
            <h2>Profile</h2>
            <h4 class='settings-header'>Update Email</h4>
            <div class='Update-email'>
                <form>
                <div class='Email-input'>
                    <label>Email</label>
                    <input type='text' placeholder='New email' onChange={(e) => {setEmail(e.target.value)}}></input>
                </div>
                {emailButton ? <button type='button' onClick={() => {updateEmail(email)}} class='active-save-email'>Save</button> : <button class='inactive-save-email' disabled>Save</button>}
                </form>
                {emailButton || email === '' ? null : <p class='error-messaging'>{emailMessaging}</p>}
            </div>
            <h4 class='settings-header '>Update Password</h4>
            <div class='Update-password'>
                <div class='Password-input'>
                    <div>
                        <label>Old Password</label>
                        <input type='password' placeholder='Old Password' onChange={(e) => {setOldPassword(e.target.value)}}></input>
                    </div>
                    <div>
                        <label>New Password</label>
                        <input type='password' placeholder='New Password' onChange={(e) => {setPassword(e.target.value)}}></input>
                    </div>
                    <div>
                        <label>Confirm New Password</label>
                        <input type='password' placeholder='Confirm New Password' onChange={(e) => {setConfirmPassword(e.target.value)}}></input>
                    </div>
                </div>
                {passwordButton === true ? <button class='active-save-password' onClick={() => {updatePassword(oldPassword,password,confirmPassword)}}>Save</button> : <button class='inactive-save-password' disabled>Save</button> }
            </div>
            {passwordButton === 'Not equal' ? <p class='password-error'>Passwords must match!</p> : null}
            <h4 class='settings-header'>Delete Account</h4>
            <div class='Delete-account'>
                <button onClick={() => {toggleDeletePop()}}>Delete</button>
            </div>
        {deletePop ? <DeleteAccountCard onDeleteUser={deleteUser} onToggleDeletePop={toggleDeletePop} /> : null}
        </div>
    )   
  }
  
  export default Profile;