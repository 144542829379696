/* eslint-disable */
import { useState, useEffect, useCallback } from 'react';
import { useLocation} from 'react-router-dom';
import './roadmap.css';
import RoadmapCard from '../components/roadmap-card.js';
import FeedbackPopUp from '../components/feedback-pop-up.js';
import axios from 'axios';
import Error from './error.js';
import Navigation from '../Navigation.js';
import PasswordInput from '../components/password.js';
import Loading from '../components/loading.js';
import FeedbackSuccess from '../components/feedbacksuccess.js';


function Roadmap() {
  const [filter, setFilter] = useState('Now');
  const [tasks, setTasks] = useState([]);
  const [membershipsSections, setMembershipsSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [dashboardData, setDashboardData] = useState(null);

  const [selectedLink, setSelectedLink] = useState(null);
  const location = useLocation();

  const [passwordinserted, setPasswordInserted] = useState(false)

  const [successfeedback, setSuccessfeedback] = useState(false)

  useEffect(() => {
    const path = location.pathname;
    const cleanedPath = path.replace('/app/', ''); // Remove the "/app/" prefix
    setSelectedLink(cleanedPath);
  }, [location.path]);
  
  useEffect(() => {
    (async () => {
      try {
        if (selectedLink != null) {
          const response = await axios.get(`https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/fetch_asana_tasks/`, {
            params: { 'dashboard_link' : selectedLink },
          });
          console.log('Fetched data:', response.data);

          // Use dashboardData directly from the response
          const { tasks, dashboardData } = response.data;
          console.log('Fetched data:', tasks, dashboardData);
  
          if (tasks && dashboardData) {
            setDashboardData(dashboardData);
            setTasks(tasks)
  
            if (dashboardData.password_required === false) {
              setPasswordInserted(true);
            }
  
            const uniqueSections = new Set();
            tasks.forEach((task) => {
              if (task.memberships && Array.isArray(task.memberships)) {
                task.memberships.forEach((membership) => {
                  if (membership.section && membership.section.name) {
                    uniqueSections.add(membership.section.name);
                  }
                });
              }
            });
  
            setMembershipsSections(Array.from(uniqueSections));
            setIsLoading(false); // Data is loaded
          } else {
            console.error('Received empty or undefined tasks or dashboardData.');
          }
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    })();
  }, [selectedLink]);
  


  function renderCards(cardData, filter) {
    // If data is still loading, return a loading indicator
    if (isLoading) {
      return <p>Loading...</p>;
    }

    const filteredCards = cardData.filter((card) => {
      const membership = card.memberships.find((m) => m.section);
      return membership && membership.section.name === filter;
    });

    return filteredCards.map((card, index) => (
      <RoadmapCard
        key={index}
        Title={card.name}
        Image={card.attachments && card.attachments.length > 0 ? card.attachments[0].download_url : ''}
        Filter={filter}
        description={card.notes}
      />
    ));
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const changeModal = () => {
    setIsModalOpen(!isModalOpen);
  };

async function authenticateDashboardPassword(password) {
  try {
    // Define the API endpoint and request parameters
    const apiUrl = `https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/authenticate_dashboard_password/`;
    const requestData = {
      method: 'GET', // Adjust the HTTP method as needed
      url: apiUrl,
      params: {
        dashboard: selectedLink,
        password: password,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // Send the request
    const response = await axios(requestData);

    // Check the response status and handle accordingly
    if (response.status === 200) {
      // Password authentication succeeded
      setPasswordInserted(true)
      return true;
    } else {
      // Password authentication failed, handle the error response
      console.error('Password authentication failed:', response.data);
      return false;
    }
  } catch (error) {
    // Handle network errors or other exceptions
    console.error('Error authenticating dashboard password:', error);
    return false;
  }
}

const onSuccess = () => {
  setSuccessfeedback(!successfeedback)
}

const [shouldShowPortal, setShouldShowPortal] = useState(false);

const axiosInstance = axios.create({
  baseURL: 'https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/', // Update with your API URL
  headers: {
    'Content-Type': 'application/json',
  },
});

useEffect(() => {
  const fetchPortalStatus = async () => {
      try {
          const response = await axiosInstance.get(`check_portal_view/${selectedLink}`);
          setShouldShowPortal(response.data.show_portal);
      } catch (error) {
          console.error('Portal Status API failed:', error);
      }
  };

  fetchPortalStatus();
}, [selectedLink]);

return (
  <div className="Roadmap">
    {dashboardData === null || dashboardData === undefined ? (
      <Loading/>
    ) : (
      <>
        {dashboardData.length === 0 || shouldShowPortal === false ? (
          <Error />
        ) : (
          <>
            {dashboardData.password_required === true && passwordinserted === false ? (
              <PasswordInput onPasswordSubmit={authenticateDashboardPassword} title={dashboardData.title} />
            ) : (
              <>
                <Navigation />
                <div className='roadmap-header'>
                  <h1>Welcome to {dashboardData.title}</h1>
                  <p>{dashboardData.description}</p>
                  <button onClick={changeModal}>+ Give us feedback</button>
                  {isModalOpen && (
                    <div>
                      <FeedbackPopUp onSuccess={onSuccess} function={changeModal} dashboard={dashboardData.id}/>
                    </div>
                  )}
                </div>
                <div className='roadmap-filters'>
                  {membershipsSections.map((section) => (
                    <p
                      key={section}
                      className={filter === section ? 'active' : 'inactive'}
                      onClick={() => setFilter(section)}
                    >
                      {section}
                    </p>
                  ))}
                </div>
                <div className='roadmap-table'>
                  <div className='roadmap-table-inner'>
                    {renderCards(tasks, filter)}
                  </div>
                </div>
                {successfeedback ? <FeedbackSuccess exitfunction={onSuccess}/> : null}
              </>
            )}
          </>
        )}
      </>
    )}
  </div>
);
  
}

export default Roadmap;