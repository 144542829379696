import './DashboardDropDown.css'
import { Link } from 'react-router-dom'


function DashboardDropDown({index, onClose, onDashboardEditName, onDashboardEditLink, onDashboardEditPassword, onDashboardSetPassword, onDeleteDashboard, dashboard}) {

    return (
        <div id={`dashboard-dropdown-${index}`} class='DashboardDropDown'>
            <p onClick={onClose} className='Close-Dashboard'>X</p>
            <p onClick={onDeleteDashboard}>Delete Dashboard</p>
            <p onClick={onDashboardEditName}>Change Title</p>
            <p onClick={onDashboardEditLink}>Change link</p>
            <Link to={`./feedback/${dashboard.id}`} class='feedback-link'><p>View feedback</p></Link>
            {dashboard.password_required ? <p onClick={onDashboardEditPassword}>Update Password</p> : <p onClick={onDashboardSetPassword} >Set Password</p>}
        </div>
    )
}

export default DashboardDropDown