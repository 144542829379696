import './login-mobile.css';
import React, { useState } from 'react';

import axios from 'axios';

import img from '../static/looby1.png'

const LoginMobile = () => {

  const [message,setMessage] = useState('')

    // Function to send a registration POST request
    const registerUser = async (registerData) => {
    try {
      const response = await axios.post('https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/register/', registerData);
      // Handle success, e.g., show a success message or redirect to a login page
      if (response.status === 200) {
      window.location.reload()
      }
    } catch (error) {
      // Handle registration error, e.g., display an error message to the user
      console.error('Registration failed:', error);
    }
    };

    const [loginData, setLoginData] = useState({
      'email': '',
      'password': '',
    });
  
    const [registerData, setRegisterData] = useState({
      'Name': '',
      'email': '',
      'password': '',
    });

    // Function to send a login POST request
    const loginUser = async (loginData) => {
      try {
        const response = await axios.post('https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/login/', loginData);
        // Handle login success, e.g., store the user token in local storage and redirect
        if (response.status === 200) {
          localStorage.setItem('token', response.data.token); 
          window.location.reload()
        }
      } catch (error) {
        // Handle login error, e.g., display an error message to the user
        console.error('Login failed:', error);
        setMessage("Invalid credentials")
      }
    };

  
    return (
    <div class='Login-mobile '>
            <img class='login-logo-mobile'  src={img} width='300' alt=''></img>
          <div className="form-container-mobile sign-in-container-mobile">
          <form action="#">
              <h1>Sign in</h1>
              <div className="social-container"></div>
              <input
                type="text"
                placeholder="Email"
                value={loginData.email}
                onChange={(e) =>
                  setLoginData({
                    ...loginData,
                    email: e.target.value,
                  })
                }
              />
              <input
                type="password"
                placeholder="Password"
                value={loginData.password}
                onChange={(e) =>
                  setLoginData({
                    ...loginData,
                    password: e.target.value,
                  })
                }
              />
              <a href="./forgot">Forgot your password?</a>
              <button onClick={()=> {loginUser(loginData)}}>Sign In</button>
              <p className='sign-in-error-message'>{message}</p>
            </form>
          </div>
          <div className="form-container-mobile sign-up-container-mobile">
          <form action="#">
              <h1>Create Account</h1>
              <div className="social-container"></div>
              <input
                type="text"
                placeholder="Name"
                value={registerData.name}
                onChange={(e) =>
                  setRegisterData({
                    ...registerData,
                    name: e.target.value,
                  })
                }
              />
              <input
                type="email"
                placeholder="Email"
                value={registerData.email}
                onChange={(e) =>
                  setRegisterData({
                    ...registerData,
                    email: e.target.value,
                  })
                }
              />
              <input
                type="password"
                placeholder="Password"
                value={registerData.password}
                onChange={(e) =>
                  setRegisterData({
                    ...registerData,
                    password: e.target.value,
                  })
                }
              />
              <button onClick={() => {registerUser(registerData)}}>Sign Up</button>
            </form>
          </div>
      </div>
    );
  };

export default LoginMobile;