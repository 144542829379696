import './ResetPassword.css'
import { useState } from 'react';
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import logo from '../static/looby1.png'

function ResetPassword() {
    const [password, setPassword] = useState('')
    const [confirmpassword, setConfirmpassword] = useState('')
    const [errormessage, setErrormessage] = useState('')
    const [successmessage, setSuccessmessage] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/reset_password/${pathname}`, { password, confirmpassword });
            setSuccessmessage("Password successfully changed. Please login at www.looby.co.uk/login using your new password")
        } catch (error) {
            setErrormessage(error)
        }
    }

    const location = useLocation();
    let pathname = location.pathname;
    const partToRemove = '/reset-password/';
    pathname = pathname.replace(partToRemove, '');

    return (
      <div className="ResetPassword">
        <img src={logo} alt='logo' class='reset-logo'/>
        <form onSubmit={handleSubmit} class='reset-password-form'>
        <p>Enter your new password below</p>
            <label>Password</label>
            <input
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <label>Confirm Password</label>
            <input
                type='password'
                value={confirmpassword}
                onChange={(e) => setConfirmpassword(e.target.value)}
            />
            {password === confirmpassword && password !== '' ? <button type="submit" class='reset-password-button'>Submit</button> : <button class='reset-password-disabled-button' disabled={true}>Submit</button>}
            {password !== confirmpassword && password !== '' && confirmpassword !== '' ? <p>Passwords must match!</p> : null}
            {errormessage ? <p>{errormessage.response.data.message}</p> : null}
            {successmessage ? <p className='message-response'>{successmessage}</p> : null}
        </form>
      </div>
    );
  }
  
  export default ResetPassword;