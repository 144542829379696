import logo from '../static/looby.png'
import './error.css'
import { Link } from 'react-router-dom';

function Error() {

    return (
      <div className="Error">
        <img width='340' src={logo} alt=''/>
        <h4>Sorry this page doesn't exist, please check your link</h4>
        <Link to='../app/'><button>Back to account</button></Link>
      </div>
    );
  }
  
  export default Error;