import './roadmap-card-pop-up.css'

function RoadmapCardPopUp(props) {

    return (
        <div className="roadmap-pop-up-table">     
            <div className="Roadmap_Card_Pop_Up">
                <div className="Roadmap_Card_Pop_Up_Top_Container">
                    <img className="Roadmap_Card_Pop_Up_Top" src={props.Image} alt=''>
                    </img>
                </div>
                <h1>{props.Title}</h1>
                <p>{props.description}</p>
            </div>
        </div>
    );
  }
  
  export default RoadmapCardPopUp;