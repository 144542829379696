import './ForgotPassword.css'
import { useState, useEffect } from 'react';
import axios from 'axios'
import {Link} from 'react-router-dom'
import logo from '../static/looby1.png'

function isValidEmail(email) {
    // Regular expression for basic email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

function ForgotPassword() {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [isEmailValid, setIsEmailValid] = useState(false);

    useEffect(() => {
      setIsEmailValid(isValidEmail(email));
    }, [email]);

    const handleSubmit = async (e) => {
        setMessage(null)
        e.preventDefault();

        try {
            await axios.post('https://portal-backend-luuby-695d88ad0aff.herokuapp.com/api/forgot-password/', { email });
            setMessage('Password reset email sent. Please check your inbox.');
        } catch (error) {
            setMessage('Email not found. Please try again or sign up above.')
        }
    }

    return (
      <div className="Forgot">
        <img src={logo} alt='logo' class='forgot-logo'/>
        <form onSubmit={handleSubmit} class='forgot-password-form'>
            <p>Enter the email address associated with your account and we'll send you a link to reset your password.</p>
            <label>Email</label>
            <input
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            {isEmailValid ? <button type="submit" class='forgot-password-button'>Continue</button> : <button class='forgot-password-button-disabled' type="submit" disabled={true}>Continue</button>}
        </form>
        <p class='no-account'>Don't have an account? <Link to='../login' class='Sign-up-link'>Sign up</Link></p>
        {message === null ? <p>Loading</p> : message === '' ? null : <p className='message-response'>{message}</p>}
      </div>
    );
  }
  
  export default ForgotPassword;