import './App.css';
/*import { useState, useEffect } from 'react';
import {Link, useLocation } from 'react-router-dom';

import logo from './static/logo.png'*/

function Navigation() {
    /*const [currentPath, setCurrentPath] = useState('');*/
    /*const location = useLocation(); // Get the current location object*/

    /*useEffect(() => {
        setCurrentPath(location.pathname); // Update currentPath when location changes
    }, [location.pathname]); // Run the effect whenever location.pathname changes*/

    return (
        <div className="Navigation">
            {/*<ul>
                <Link to="/app/roadmap" className={currentPath === '/roadmap' ? 'nav-active' : 'nav-inactive'}>
                    <li>Roadmap</li>
                </Link>
                <Link to="/app/dashboard" className={currentPath === '/dashboard' ? 'nav-active' : 'nav-inactive'}>
                    <li>Dashboard</li>
                </Link>
    </ul>*/}
            {/*<img height='80' src={logo} alt='logo'/>*/}
        </div>
    );
}

export default Navigation;
