import RoadmapCardPopUp from './roadmap-card-pop-up';
import './roadmap-card.css';
import { useState, useEffect } from 'react';

function RoadmapCard(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const changeModal = () => {
    setIsModalOpen(!isModalOpen)
  };

  useEffect(() => {
    console.log('isModalOpen:', isModalOpen);
  }, [isModalOpen]);

  return (
    <div className="Roadmap_Card" onClick={changeModal}>
      <div className="Roadmap_Card_Title">{props.Title}</div>
      {props.Image ? <img className="Roadmap_Card_Image" src={props.Image} alt="Attachment"></img> : null}
      {isModalOpen && (
        <div>
          <RoadmapCardPopUp Title={props.Title} description={props.description} Image={props.Image}  />
        </div>
      )}
    </div>
  );
}

export default RoadmapCard;
