import React, { useState } from 'react';
import './password.css'

function PasswordInput({ onPasswordSubmit, title }) {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('')
  const [data,setData] = useState('')

  const handleSubmit = async () => {
      setData(onPasswordSubmit(password));
      if (!data) {
        setMessage('Password incorrect');
      }
  };

  return (
    <div class='PasswordInput'>
      <div>
      <p>Please enter the password for the <b>{` ${title} `}</b> portal</p>
      </div>
      <form>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleSubmit} type='button'>Submit</button>
      </form>
      <p class='error-message'>{message}</p>
    </div>
  );
}

export default PasswordInput;
