import './loading.css'

function Loading() {

    return (
        <div class="loading-container">
            <div class="loading-spinner">
            </div>
        </div>
    )
};

export default Loading;