import React, { useState } from "react";
import { Link } from "react-router-dom";

import './settings.css'
import Profile from "../components/SettingsProfile";
import Billing from "../components/SettingsBilling";

const Settings = () => {

    const [selectedValue, setSelectedValue] = useState('value1');

    return (
            <div class='Settings'>
                <Link class='back-to-dashboard' to='/app'><button >Back to Dashboards</button></Link>
                <div class='left-settings'>
                    <div class='left-settings-inner'>
                        <h2>Settings</h2>
                        <button class={selectedValue === 'value1' ? 'active-setting' : 'inactive-setting'} onClick={() => setSelectedValue('value1')}>Profile</button>
                        <button class={selectedValue === 'value2' ? 'active-setting' : 'inactive-setting'} onClick={() => setSelectedValue('value2')}>Billing</button>
                    </div>
                </div>
                <div class='right-settings'>
                    {selectedValue === 'value1' ? <Profile/> : null}
                    {selectedValue === 'value2' ? <Billing/> : null}
                </div>
            </div>
  );
};

export default Settings;