import axios from 'axios';

const API_BASE_URL = 'https://app.asana.com/api/1.0';

const createAsanaService = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const fetchWorkspaces = async (token) => {
  try {
    const asanaService = createAsanaService(token);
    const response = await asanaService.get('/workspaces');
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default fetchWorkspaces;

/*1/1205276079897753:e0a200dd83a0bfb7a6b7d40a19013c9f*/